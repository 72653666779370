import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import CardDeck from "react-bootstrap/CardDeck"
import { FaBookOpen, FaUser, FaUserTie } from "react-icons/fa"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="SGYAK2014B"/>
      <Container fluid={true}>
        <CardDeck>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title><h4><FaUser/><br/>Diákoknak</h4></Card.Title>
              <Card.Text>
                Kisokos diákoknak.
              </Card.Text>
              <Button variant="primary" href="/diakoknak">Ugrás</Button>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title><h4><FaUserTie/><br/>Tanároknak</h4></Card.Title>
              <Card.Text>
                Kisokos tanároknak.
              </Card.Text>
              <Button variant="primary" href="/tanaroknak">Ugrás</Button>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title><h4><FaBookOpen/><br/>Minden cikk</h4></Card.Title>
              <Card.Text>
                Az összes eddigi cikk.
              </Card.Text>
              <Button variant="primary" href="/all">Ugrás</Button>
            </Card.Body>
          </Card>
        </CardDeck>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
